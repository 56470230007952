<template>
    <div class="row barcode-inputs mb-3">
        <div class="col-lg-5">
            <form @submit.prevent="addBarcode" class="mb-3 h-100">
                <fieldset class="h-100">
                    <legend>
                        <i class="icon ph-bold ph-barcode me-2"></i>Barcode to be Move
                    </legend>
                    <div class="row h-100 align-items-center">
                        <div class="col mb-2">
                            <FormInput
                                type="text"
                                label="Item"
                                v-model="barcodeInput"
                                id-prefix="barcode"
                                is-horizontal
                                @keydown-enter="addBarcode"
                                label-class="col-auto label-sm align-items-center"
                                input-class="form-control-sm"
                                input-container-class="col"
                            />
                        </div>
                        <div class="col-auto text-end mb-2 ms-auto">
                            <button type="submit"
                                class="btn btn-primary btn-sm"
                                :disabled="!comment"
                                id="addBarcodeButton">
                                <i class="icon ph-bold ph-file-plus me-2"></i>Add
                            </button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
        <div class="col-lg-4">
            <form @submit.prevent="addNoBarcode" class="mb-3 h-100">
                <fieldset class="h-100">
                    <legend>
                        <i class="icon ph-bold ph-image me-2"></i>Item to Move (NO BARCODE)
                    </legend>
                    <p class="mb-3 text-primary">**for Sales and Returns only</p>
                    <div class="row">
                        <div class="col mb-2">
                            <FormInput
                                type="text"
                                label="Style"
                                v-model="styleInput"
                                id-prefix="itemNoBarcode"
                                is-horizontal
                                @keydown-enter="addNoBarcode"
                                label-class="col-auto label-sm"
                                input-class="form-control-sm"
                            />
                        </div>
                        <div class="col-auto mb-2 text-end ms-auto">
                            <button type="submit"
                                class="btn btn-primary btn-sm"
                                id="addNoBarcodeButton"
                                :disabled="!comment || (comment.MOVEMENT_TYPE != 'SALES' && comment.MOVEMENT_TYPE != 'RETURN')">
                                <i class="icon ph-bold ph-file-plus me-2"></i>Add
                            </button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
        <div class="col-lg-3">
            <StyleImageCard
                :style="item?.STYLE"
                class="barcode-preview"
            />
        </div>
    </div>
    <div class="row g-3">
        <div class="col-xl-12">
            <SelectCommentSection
                v-model="comment"
                v-model:sell-to-employee="sellToEmployee"
                v-model:previous-location="previousLocation"
            />
        </div>
    </div>

    <div class="row">
        <div class="col-12 mb-3">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-arrows-left-right me-2"></i> Items to be moved
                </legend>
                <div class="row g-3 mb-3">
                    <div class="col-xl-3 col-lg-6">
                        <FormInput
                            label="Total Count"
                            v-model="totalBarcodesCount"
                            is-horizontal
                            disabled
                            id-prefix="count"
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-md-5 label-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                        />
                    </div>
                    <div class="col-xl-3 col-lg-6">
                        <FormInput
                            label="Total Price"
                            v-model="totalBarcodesPrice"
                            is-horizontal
                            disabled
                            id-prefix="totalprice"
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-md-5 label-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                        />
                    </div>
                    <div class="col-xl-6">
                        <div class="text-end">
                            <button type="button"
                                class="btn btn-outline-danger me-2 mb-1 btn-sm"
                                :disabled="!selectedItems?.length"
                                @click="deleteSelectedItems">
                                <i class="icon ph-bold ph-trash me-2"></i>Delete from List
                            </button>
                            <button type="button"
                                class="btn btn-primary me-2 mb-1 btn-sm"
                                :disabled="itemsToMove.length == 0"
                                @click="moveItems">
                                <i class="icon ph-bold ph-arrows-left-right me-2"></i>Move Items
                            </button>
                        </div>
                    </div>
                </div>
                <DataTable
                    :value="itemsToMove"
                    v-model:selection="selectedItems"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }"
                    class="mb-3">
                    <Column selection-mode="multiple" />
                    <Column
                        field="BARCODE"
                        header="Barcode"
                        :pt="{ bodyCell: { class: 'barcode' } }"
                    />
                    <Column field="STYLE" header="Style" />
                    <Column field="DATE" header="Date Arrived" />
                    <Column field="SERIAL" header="Serial" />
                    <Column field="PREV_LOC" header="Current Location" />
                    <Column field="SALE_TYPE" header="Sale Type" />
                    <Column field="RECEIPT_NO" header="Receipt" />
                    <Column field="COLOR" header="Color" />
                    <Column field="PRICE" header="Price" />
                    <Column field="SOLD_DT" header="Sold Date" />
                    <Column field="EMPID" header="Employee ID" />
                    <Column field="employee.FNAME" header="First Name" />
                    <Column field="employee.LNAME" header="Last Name" />
                    <Column field="DISCOUNT" header="Discount" />
                    <template #empty>No items.</template>
                </DataTable>
            </fieldset>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-xl-4" v-if="comment?.MOVEMENT_TYPE == 'SALES'">
            <SalesReportCard
                :previous-location="previousLocation"
                :comment="comment"
            />
        </div>
        <div
            v-if="['SALES', 'RETURN'].includes(comment?.MOVEMENT_TYPE)"
            :class="{
                'col-xl-8': comment?.MOVEMENT_TYPE == 'SALES',
                'col-xl-12': comment?.MOVEMENT_TYPE != 'SALES',
            }"
        >
            <NoBarcodesCard
                :previous-location="previousLocation"
                :comment="comment"
            />
        </div>
    </div>

    <!-- For final details of item movement -->
    <OtherSelectUnifiedPopup
        :is-open="isOtherSelectUnifiedPopupOpen"
        :previous-location="previousLocation"
        :comment="comment"
        :available-employees-filters="availableEmployeesFilters"
        :items-to-move="itemsToMove"
        :item-to-move-type="itemToMoveType"
        :barcode-latest-log="barcodeInputLatestLog"
        v-model="item"
        v-model:partner-item="partnerItem"
        v-model:sold-date-retained="soldDateRetained"
        v-model:employee-retained="employeeRetained"
        v-model:sold-date-month-to-enforce="soldDateMonthToEnforce"
        @success="
            (_) => {
                markBarcodeForMovement();
                isOtherSelectUnifiedPopupOpen = false;
            }
        "
        @buy-one-take-one-success="
            (_) => {
                markPartnerBarcodesForMovement();
                isOtherSelectUnifiedPopupOpen = false;
            }
        "
        @close="
            (_) => {
                isOtherSelectUnifiedPopupOpen = false;
                movementType = null;
            }
        "
        @error="
            (message) => {
                toasts.add('ERROR', 'Error', message);
                isOtherSelectUnifiedPopupOpen = false;
            }
        "
    />
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useBarcodesInventory } from "@/composables/data/barcodesInventory";
import { useStyles } from "@/composables/data/styles";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useInventory } from "@/composables/data/inventory";
import FormInput from "@/components/utils/FormInput.vue";
import StyleImageCard from "@/components/utils/StyleImageCard.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import OtherSelectUnifiedPopup from "./other-select-unified-popup/";
import NoBarcodesCard from "./NoBarcodesCard.vue";
import SalesReportCard from "./SalesReportCard.vue";
import focusNextInput from "@/utils/focusnext";
import SelectCommentSection from "./SelectCommentSection.vue";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const previousLocation = ref(null);
const sellToEmployee = ref(null);
const comment = ref(null);

const itemsToMove = ref([]);
const selectedItems = ref(null);

const item = ref({
    BARCODE: null,
    STYLE: null,
    DATE: null,
    SERIAL: null,
    PREV_LOC: null,
    PRICE: null,
    DISCOUNT: null,
    SOLD_DT: null,
    COLOR: null,
    SALE_TYPE: null,
    RECEIPT_NO: null,
    EMPID: null,
    SELL_TO_TYPE: null,
    SELL_TO_ID: null,
});

const partnerItem = ref({
    BARCODE: null,
    STYLE: null,
    DATE: null,
    SERIAL: null,
    PREV_LOC: null,
    PRICE: null,
    DISCOUNT: null,
    COLOR: null,
});

const itemToMoveType = ref(null);

const barcodeInput = ref(null); // For BARCODE
const styleInput = ref(null); // For NOBC

const barcodeInputLatestLog = ref(null);

const isOtherSelectUnifiedPopupOpen = ref(false);

const { postInventoryMovements } = useInventory();

const { getBarcodeLatestInformation } = useBarcodesInventory();

const { getStyle } = useStyles();

const soldDateRetained = ref(null); // For retaining sold date, continuous adding
const employeeRetained = ref(null); // For retaining employee, continuous adding

const soldDateMonthToEnforce = ref(null);

const totalBarcodesCount = computed(() => itemsToMove.value.length);
const totalBarcodesPrice = computed(() =>
    itemsToMove.value
        .reduce(
            (total, barcodeEntry) =>
                total + (barcodeEntry.DISCOUNT || barcodeEntry.PRICE),
            0
        )
        .toFixed(2)
);

const availableEmployeesFilters = ref(null);

watch(comment, () => {
    availableEmployeesFilters.value = null;

    itemsToMove.value = [];
    itemToMoveType.value = null;

    soldDateRetained.value = null;
    employeeRetained.value = null;

    soldDateMonthToEnforce.value = null;
});

function clearItem() {
    item.value = {
        BARCODE: null,
        STYLE: null,
        DATE: null,
        SERIAL: null,
        PREV_LOC: null,
        PRICE: null,
        DISCOUNT: null,
        SOLD_DT: null,
        COLOR: null,
        SALE_TYPE: null,
        RECEIPT_NO: null,
        EMPID: null,
        SELL_TO_TYPE: null,
        SELL_TO_ID: null,
    };

    partnerItem.value = {
        BARCODE: null,
        STYLE: null,
        DATE: null,
        SERIAL: null,
        PREV_LOC: null,
        PRICE: null,
        DISCOUNT: null,
        COLOR: null,
    };

    itemToMoveType.value = null;
}

async function addBarcode() {
    if (!comment.value) {
        toasts.add("ERROR", "Error", "Please specify a comment first.");
        return;
    }

    clearItem();

    barcodeInput.value = barcodeInput.value.toUpperCase();

    if (itemsToMove.value.filter(itemToMove => itemToMove.BARCODE == barcodeInput.value).length > 0) {
        toasts.add("ERROR", "Error", "Barcode is already inputted for movement.");
        return;
    }

    loadingFlags.add("fetchBarcodeLatestInformation");
    try {
        const barcodeLatestInformation = await getBarcodeLatestInformation(
            barcodeInput.value,
            comment.value.COMMENT_RPT_DATE
        );

        if (barcodeLatestInformation.legacy) {
            toasts.add(
                "SUCCESS",
                "Success",
                `Swapped legacy ${barcodeLatestInformation.legacy.BARCODE_LEGACY} ` +
                `with ${barcodeLatestInformation.legacy.BARCODE_NEW}.`
            );
            barcodeInput.value = barcodeLatestInformation.legacy.BARCODE_NEW;
        }

        const barcodeLatestLog = barcodeLatestInformation.latest;

        if (barcodeLatestLog.comment.LOCATION != previousLocation.value.LOCATION) {
            throw new Error(
                `Barcode current location ${barcodeLatestLog.comment.LOCATION} ` +
                `does not match inputted previous location ${previousLocation.value.LOCATION}.`
            );
        }

        if (
            itemsToMove.value.filter(
                (barcodeEntry) => barcodeEntry.barcode == barcode.value
            ).length > 0
        ) {
            throw new Error("Barcode is already inputted for movement.");
        }

        if (
            comment.value.MOVEMENT_TYPE != 'RETURN' &&
            comment.value.MOVEMENT_TYPE != 'SALES' &&
            !barcodeLatestLog.style.style_prices.length
        ) {
            throw new Error("Cannot move, item has no available price for this date.");
        }

        item.value.BARCODE = barcodeInput.value;
        item.value.STYLE = barcodeLatestLog.STYLE;
        item.value.DATE = barcodeLatestLog.DATE;
        item.value.SERIAL = barcodeLatestLog.SERIAL;
        item.value.PREV_LOC = barcodeLatestLog.comment.LOCATION;
        item.value.DISCOUNT = 0;
        item.value.COLOR = barcodeLatestLog.COLOR;
        item.value.SOLD_DT = soldDateRetained.value;
        item.value.SALE_TYPE = 1;
        item.value.EMPID = employeeRetained.value?.EMPID ?? 0;
        item.value.SELL_TO_TYPE = 'I';
        item.value.SELL_TO_ID = sellToEmployee.value?.EMPID ?? 0;

        barcodeInputLatestLog.value = barcodeLatestLog;

        if (comment.value.MOVEMENT_TYPE == 'RETURN') {
            item.value.PRICE = -barcodeLatestLog.PRICE;
            item.value.EMPID = barcodeLatestLog.EMPID;
            item.value.SOLD_DT = barcodeLatestLog.SOLD_DT;
            item.value.RECEIPT_NO = barcodeLatestLog.RECEIPT_NO;

            availableEmployeesFilters.value = {
                LOCATION: {
                    value: comment.value.LOCATION,
                    matchMode: 'equals',
                },
                EMPID: {
                    value: barcodeLatestLog.EMPID,
                    matchMode: 'equals',
                },
            };
        } else if (comment.value.MOVEMENT_TYPE == 'SALES') {
            availableEmployeesFilters.value = {
                LOCATION: {
                    value: previousLocation.value.LOCATION,
                    matchMode: 'equals',
                },
            };
        } else {
            availableEmployeesFilters.value = {
                LOCATION: {
                    value: comment.value.LOCATION,
                    matchMode: 'equals',
                },
                EMPID: {
                    value: 0,
                    matchMode: 'equals',
                },
            };

            if (comment.value.location.PRICE_TYPE == 'S') {
                item.value.PRICE = barcodeLatestLog.style.style_prices[0].SM_PRICE;
            } else {
                item.value.PRICE = barcodeLatestLog.style.style_prices[0].LM_SALE_PRICE;
            }
        }

        itemToMoveType.value = "BARCODE";
        isOtherSelectUnifiedPopupOpen.value = true;
    } catch (e) {
        console.log(e);
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchBarcodeLatestInformation");
}

async function addNoBarcode() {
    if (!comment.value) {
        toasts.add("ERROR", "Error", "Please specify a comment first.");
        return;
    }

    clearItem();

    try {
        if (!styleInput.value) {
            throw new Error("Please specify STYLE.");
        }

        try {
            await getStyle(styleInput.value);
        } catch (e) {
            if (e.status == 404) {
                throw new Error("Style does not exist.");
            } else {
                throw e;
            }
        }

        // For filtering which employees to show in the modal
        if (comment.value.MOVEMENT_TYPE == 'RETURN') {
            availableEmployeesFilters.value = {
                LOCATION: {
                    value: comment.value.location.LOCATION,
                    matchMode: 'equals',
                },
            };
        } else {
            availableEmployeesFilters.value = {
                LOCATION: {
                    value: previousLocation.value.LOCATION,
                    matchMode: 'equals',
                },
            };
        }
        
        item.value.STYLE = styleInput.value;
        item.value.PREV_LOC = previousLocation.value.LOCATION;
        item.value.SOLD_DT = soldDateRetained.value;
        item.value.SALE_TYPE = 1,
        item.value.EMPID = employeeRetained.value?.EMPID ?? 0;
        item.value.DISCOUNT = 0;

        itemToMoveType.value = "NOBC";
        isOtherSelectUnifiedPopupOpen.value = true;
    } catch (e) {
        console.log(e);
        toasts.add("ERROR", "Error", e.message);
    }
}

function markBarcodeForMovement() {
    itemsToMove.value.push({
        ...item.value,
        index: itemsToMove.value.length,
    });
    clearItem();

    if (itemToMoveType.value == "BARCODE") {
        focusNextInput("barcodeFormInput");
    } else if (itemToMoveType.value == "NOBC") {
        focusNextInput("itemNoBarcodeFormInput");
    }

    itemToMoveType.value = null;
    barcodeInput.value = null;
    styleInput.value = null;
}

function markPartnerBarcodesForMovement() {
    itemsToMove.value.push({
        ...item.value,
        index: itemsToMove.value.length,
    });
    itemsToMove.value.push({
        ...partnerItem.value,
        SOLD_DT: item.value.SOLD_DT,
        SALE_TYPE: item.value.SALE_TYPE,
        RECEIPT_NO: item.value.RECEIPT_NO,
        employee: item.value.employee,
        EMPID: item.value.EMPID,
        SELL_TO_TYPE: 'I',
        SELL_TO_ID: sellToEmployee.value?.EMPID ?? 0,
        index: itemsToMove.value.length,
    });
    clearItem();

    if (itemToMoveType.value == "BARCODE") {
        focusNextInput("barcodeFormInput");
    } else if (itemToMoveType.value == "NOBC") {
        focusNextInput("itemNoBarcodeFormInput");
    }

    itemToMoveType.value = null;
    barcodeInput.value = null;
    styleInput.value = null;
}

function deleteSelectedItems() {
    let finalItemsList = [...itemsToMove.value];

    selectedItems.value.forEach((itemToRemove) => {
        finalItemsList = finalItemsList.filter((item) => item.index != itemToRemove.index);
    });

    toasts.add(
        "SUCCESS",
        "Success",
        `Successfully removed ${selectedItems.value.length} items for movement: ` +
        selectedItems.value.map(
            selectedItem => selectedItem.BARCODE ?? `${selectedItem.STYLE} ${selectedItem.COLOR}`
        ).join(", ") + "."
    );

    selectedItems.value = [];
    itemsToMove.value = finalItemsList.map((item, index) => ({
        ...item,
        index,
    }));
}

async function moveItems() {
    loadingFlags.add('moveItems');
    try {
        const withBarcodeItems = itemsToMove.value.filter(
            (itemToMove) => itemToMove.BARCODE != null
        ).map(itemToMove => {
            const { index, employee, BARCODE, ...trimmedItemToMove } = itemToMove;
            return trimmedItemToMove;
        });

        const withoutBarcodeItems = itemsToMove.value.filter(
            (itemToMove) => itemToMove.BARCODE == null
        ).map(itemToMove => {
            const {
                index,
                employee,
                BARCODE,
                DATE,
                SERIAL,
                SELL_TO_ID,
                SELL_TO_TYPE,
                ...trimmedItemToMove
            } = itemToMove;
            return trimmedItemToMove;
        });

        await postInventoryMovements(
            comment.value.COMMENT_ID,
            withBarcodeItems,
            withoutBarcodeItems,
        );
        toasts.add("SUCCESS", "Success", "Successfully moved barcodes.");
        itemsToMove.value = [];
        soldDateMonthToEnforce.value = null;
    } catch (e) {
        console.log(e);
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete('moveItems');
}
</script>
