<template>
    <fieldset class="mb-3">
        <legend>
            <i class="icon ph-bold ph-truck me-2"></i>Purchase Orders
        </legend>
        <PurchaseOrdersTable
            v-model="selectedPurchaseOrder"
            :refresh-flag="purchaseOrdersTableRefreshFlag"
        />
        <div class="text-end">
            <Button
                type="button"
                class="btn btn-primary btn-sm me-2 mb-1"
                @click="
                    (_) => {
                        purchaseOrderBeingModified = null;
                        isCreateOrUpdatePurchaseOrderFormPopupOpen = true;
                    }
                "
            >
                <i class="fas fa-plus me-2"></i>Add Purchase Order
            </Button>
            <Button
                type="button"
                class="btn btn-primary btn-sm me-2 mb-1"
                :disabled="!selectedPurchaseOrder"
                @click="
                    (_) => {
                        purchaseOrderBeingModified = selectedPurchaseOrder;
                        isCreateOrUpdatePurchaseOrderFormPopupOpen = true;
                    }
                "
            >
                <i class="fas fa-edit me-2"></i>Update Selected
            </Button>
            <Button
                type="button"
                class="btn btn-primary btn-sm me-2 mb-1"
                :disabled="!selectedPurchaseOrder"
                @click="exportSelectedPurchaseOrderSpreadsheet"
            >
                <i class="fas fa-file me-2"></i>Export Spreadsheet
            </Button>
        </div>
    </fieldset>
    <fieldset class="mb-3">
        <legend><i class="icon ph-bold ph-list me-2"></i>Raw Orders</legend>
        <RawOrdersTable
            :purchase-order-number="selectedPurchaseOrder?.PURCHASE_ORDER_NUMBER"
            v-model="selectedRawOrder"
            :refresh-flag="rawOrdersTableRefreshFlag"
            class="mb-3"
        />
        <div class="text-end">
            <Button
                type="button"
                class="btn btn-primary btn-sm mb-1 me-2"
                @click="
                    (_) => {
                        rawOrderBeingModified = null;
                        isCreateOrUpdateRawOrderFormPopupOpen = true;
                    }
                "
            >
                <i class="fas fa-plus me-2"></i>Add Raw Order
            </Button>
            <Button
                type="button"
                class="btn btn-primary btn-sm mb-1 me-2"
                :disabled="!selectedRawOrder"
                @click="
                    (_) => {
                        rawOrderBeingModified = selectedRawOrder;
                        isCreateOrUpdateRawOrderFormPopupOpen = true;
                    }
                "
            >
                <i class="fas fa-edit me-2"></i>Update Selected
            </Button>
            <Button
                type="button"
                class="btn btn-success btn-sm mb-1 me-2"
                :disabled="!selectedRawOrder || selectedRawOrder.STATUS"
                @click="approveSelectedRawOrder"
            >
                <i class="fas fa-check me-2"></i>Approve
            </Button>
            <Button
                type="button"
                class="btn btn-danger btn-sm mb-1"
                :disabled="!selectedRawOrder || selectedRawOrder.STATUS"
                @click="cancelSelectedRawOrder"
            >
                <i class="fas fa-xmark me-2"></i>Cancel
            </Button>
        </div>
    </fieldset>
    <fieldset class="mb-3">
        <legend>
            <i class="icon ph-bold ph-list-numbers me-2"></i>Raw Order Breakdowns
        </legend>
        <RawOrderBreakdownsTable
            :order-id="selectedRawOrder?.ORDER_ID"
            :refresh-flag="breakdownTableRefreshFlag"
            v-model="selectedRawOrderBreakdown"
        />
        <div class="text-end">
            <Button
                type="button"
                class="btn btn-primary btn-sm mb-1 me-2"
                :disabled="!selectedRawOrder"
                @click="
                    (_) => {
                        rawOrderBreakdownBeingModified = null;
                        isCreateOrUpdateRawOrderBreakdownFormPopupOpen = true;
                    }
                "
            >
                <i class="fas fa-plus me-2"></i>Add Breakdown
            </Button>
            <Button
                type="button"
                class="btn btn-primary btn-sm mb-1 me-2"
                :disabled="!selectedRawOrder || !selectedRawOrderBreakdown"
                @click="
                    (_) => {
                        rawOrderBreakdownBeingModified = selectedRawOrderBreakdown;
                        isCreateOrUpdateRawOrderBreakdownFormPopupOpen = true;
                    }
                "
            >
                <i class="fas fa-edit me-2"></i>Update Selected
            </Button>
        </div>
    </fieldset>

    <CreateOrUpdatePurchaseOrderFormPopup
        v-model="purchaseOrderBeingModified"
        :is-open="isCreateOrUpdatePurchaseOrderFormPopupOpen"
        @close="
            (_) => {
                isCreateOrUpdatePurchaseOrderFormPopupOpen = false;
            }
        "
        @success="
            (_) => {
                purchaseOrdersTableRefreshFlag = !purchaseOrdersTableRefreshFlag;
                isCreateOrUpdatePurchaseOrderFormPopupOpen = false;
                selectedPurchaseOrder = purchaseOrderBeingModified;
            }
        "
    />

    <CreateOrUpdateRawOrderFormPopup
        v-model="rawOrderBeingModified"
        :is-open="isCreateOrUpdateRawOrderFormPopupOpen"
        :purchase-order="selectedPurchaseOrder"
        @close="
            (_) => {
                isCreateOrUpdateRawOrderFormPopupOpen = false;
            }
        "
        @success="
            (_) => {
                rawOrdersTableRefreshFlag = !rawOrdersTableRefreshFlag;
                isCreateOrUpdateRawOrderFormPopupOpen = false;
                selectedRawOrder = rawOrderBeingModified;
            }
        "
    />

    <CreateOrUpdateRawOrderBreakdownFormPopup
        v-model="rawOrderBreakdownBeingModified"
        :is-open="isCreateOrUpdateRawOrderBreakdownFormPopupOpen"
        :order-id="selectedRawOrder?.ORDER_ID"
        @close="
            (_) => {
                isCreateOrUpdateRawOrderBreakdownFormPopupOpen = false;
            }
        "
        @success="
            (_) => {
                rawOrdersTableRefreshFlag = !rawOrdersTableRefreshFlag;
                breakdownTableRefreshFlag = !breakdownTableRefreshFlag;
                isCreateOrUpdateRawOrderBreakdownFormPopupOpen = false;
                selectedRawOrderBreakdown = rawOrderBreakdownBeingModified;
            }
        "
    />
</template>

<script setup>
import { ref, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";

import { useRawOrders } from "@/composables/data/rawOrders";

import PurchaseOrdersTable from "@/components/utils/tables/PurchaseOrdersTable.vue";
import RawOrdersTable from "@/components/utils/tables/RawOrdersTable.vue";
import RawOrderBreakdownsTable from "@/components/utils/tables/RawOrderBreakdownsTable.vue";

import CreateOrUpdatePurchaseOrderFormPopup from "./CreateOrUpdatePurchaseOrderFormPopup.vue";
import CreateOrUpdateRawOrderFormPopup from "./CreateOrUpdateRawOrderFormPopup.vue";
import CreateOrUpdateRawOrderBreakdownFormPopup from "./CreateOrUpdateRawOrderBreakdownFormPopup.vue";

import Button from "primevue/button";

const { putRawOrder } = useRawOrders();

const toasts = useToastsStore();
const loadingFlags = useLoadingFlagsStore();

const selectedPurchaseOrder = ref(null);
const selectedRawOrder = ref(null);
const selectedRawOrderBreakdown = ref(null);

const purchaseOrderBeingModified = ref(null);
const rawOrderBeingModified = ref(null);
const rawOrderBreakdownBeingModified = ref(null);

const purchaseOrdersTableRefreshFlag = ref(false);
const rawOrdersTableRefreshFlag = ref(false);
const breakdownTableRefreshFlag = ref(false);

const isCreateOrUpdatePurchaseOrderFormPopupOpen = ref(false);
const isCreateOrUpdateRawOrderFormPopupOpen = ref(false);
const isCreateOrUpdateRawOrderBreakdownFormPopupOpen = ref(false);

watch(selectedPurchaseOrder, () => {
    selectedRawOrder.value = null;
});

watch(selectedRawOrder, () => {
    selectedRawOrderBreakdown.value = null;
});

async function approveSelectedRawOrder() {
    let promptMessage = null;

    if (
        selectedRawOrder.value.BREAKDOWNS_SUM_QUANTITY !=
        selectedRawOrder.value.QUANTITY
    ) {
        promptMessage =
            `In Raw Order #${selectedRawOrder.value.ORDER_ID}, ` +
            "quantity does not match total breakdown. Approve anyway?";
    } else {
        promptMessage = `Approve Raw Order #${selectedRawOrder.value.ORDER_ID}?`;
    }

    if (window.confirm(promptMessage)) {
        loadingFlags.add("approveSelectedRawOrder");
        try {
            const newRawOrder = await putRawOrder(
                selectedRawOrder.value.ORDER_ID,
                {
                    ...selectedRawOrder.value,
                    STATUS: "A",
                }
            );
            selectedRawOrder.value = newRawOrder;
            rawOrdersTableRefreshFlag.value = !rawOrdersTableRefreshFlag.value;
            toasts.add(
                "SUCCESS",
                "Success",
                `Successfully approved Raw Order #${selectedRawOrder.value.ORDER_ID}.`
            );
        } catch (e) {
            toasts.add("ERROR", "Error", e.message);
        }
        loadingFlags.delete("approveSelectedRawOrder");
    }
}

async function cancelSelectedRawOrder() {
    if (
        window.confirm(`Cancel Raw Order #${selectedRawOrder.value.ORDER_ID}?`)
    ) {
        loadingFlags.add("cancelSelectedRawOrder");
        try {
            const newRawOrder = await putRawOrder(
                selectedRawOrder.value.ORDER_ID,
                {
                    ...selectedRawOrder.value,
                    STATUS: "C",
                }
            );
            selectedRawOrder.value = newRawOrder;
            rawOrdersTableRefreshFlag.value = !rawOrdersTableRefreshFlag.value;
            toasts.add(
                "SUCCESS",
                "Success",
                `Successfully cancelled Raw Order #${selectedRawOrder.value.ORDER_ID}.`
            );
        } catch (e) {
            toasts.add("ERROR", "Error", e.message);
        }
        loadingFlags.delete("cancelSelectedRawOrder");
    }
}

function exportSelectedPurchaseOrderSpreadsheet() {
    window.open(
        route("api.purchase-orders.spreadsheet.show", {
            purchase_order: selectedPurchaseOrder.value.ORDER_PO_ID,
        })
    );
}
</script>
